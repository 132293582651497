import React, { useEffect, useState, Suspense } from "react";
import "./App.css";
import "./Theme/app.scss";
import "./Theme/main.scss"
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ContextModel } from "./shared/Modal/ContextModel";
import { PreferencesContext } from "./PreferencesContext";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { getStorage, setStorage } from "./Services/common/Storage";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { lightTheme } from './Theme/Palette/light-palette';
import Alerts from "./Components/Alerts";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import UnauthorizeDialog from "./Components/UnauthorizeDialog";
import SplashComponent from "./Navigation/splash";
import ScrollTop from "../src/Components/ScrollTop";
import offlineimg from './Assets/offline.png';
import { Info } from "./Services/AuthService";
import Nav from "./Navigation/navbar";
import { useNetworkCheck } from "../src/context/NetworkContext";

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#2c2ce4',
    },
    secondary: {
      main: '#2c2ce4'
    }, background: {
      default: '#303030'
    }

  },
});

function App() {

  const [mode, setMode] = useState<"light" | "dark" | undefined>(
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? "light" : "light"
  );
  const { isOnline } = useNetworkCheck();
  const [userContext, setUserContext] = React.useState<ContextModel>(getStorage());
  const [isLoading, setLoading] = useState(true);
  let navigate = useNavigate();
  let location = useLocation();

  const updateUserContext = React.useCallback((context) => {
    setStorage(context);
    return (setUserContext(context));
  }, [userContext]);

  const preferences = React.useMemo(
    () => ({
      updateUserContext, userContext,
    }), [userContext]
  );




  const changeMode = (newMode: any) => {
    setMode(newMode);
  }

  let publicPages = ['/', 'reset-password', 'set-password', 'forgot-password', 'manage-user', "terms", "privacy-policy", "android"];

  const checkIfLoggedIn = () => {

    const modeMe = (e: any) => {
      setMode(e.matches ? "dark" : "light");
    }
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', modeMe);

    let value = getStorage();
    let page = window.location.href.split(window.location.host)[1];

    let allPages = ['reset-password', 'set-password', 'forgot-password', 'manage-user', 'consumers', 'dashboard', 'suppliers', 'products', 'taxes', 'units'
      , 'purchase', 'invoice', 'delivery-challan', 'invoicereturn', 'stock', 'stock-adjustment', 'stock-adjustment-save', 'user', 'staff', 'product-category', 'branches', 'firms', 'buyers', 'gst-report', 'my-profile', 'product-category-save'
      , 'product-save', 'unit-save', 'purchase-save', 'PurchaseSave', 'invoice-Setting', 'invoice-save', 'SaleSaveReturn', 'SaleSave', 'buyer-save', 'supplier-save', 'user-save',
      , 'consumer-save', 'staff-save', 'tax-save', 'branch-save', 'firm-save', 'help-center', 'delivery-challan-save', 'sales-register', 'balance-sheet', 'ledger-group-summary', 'ledger-monthly-summary', 'ledger-voucher', 'day-book', 'daily-purchases-report', 'daily-sales-report', 'staff-summary-report', 'sales-details-by-saleperson',
      , 'sales-summery-by-customer','outstanding-receivable-summary','outstanding-payable-summary', 'shipping-address', 'shipping-address-save', 'credit-note', 'credit-note-save', 'debit-note', 'debit-note-save', 'settings', 'report-menu', 'ledger', 'ledger-save', 'purchase-return', 'sale-return',
      'transporters', 'transporter-save', "payment-made", "payment-made-save", "payment-received", "payment-received-save", "voucher", "voucher-save", "journal-voucher", "journal-voucher-save"
      , "warehouses", "warehouse-save", "ledger-groups", "ledger-group-save", "stock-statement", "profit-and-loss", "terms", "privacy-policy", "trial-balance", "purchase-register", "android", "subscription", "e-way-bill", "e-way-bill-update", "e-way-bill-extend"
      , "price-list", 'gstr1-report', 'pos', 'areawise-report', 'area', 'area-save', 'stock-summary-by-supplier-report', 'stock-summary-by-buyer-report', 'stock-detail-by-buyer-report', 'stock-summary-by-product-buyer-report', 'free-good-summary-by-category-report',
      'free-good-detail-by-buyer-report', 'free-good-summary-by-buyer-report', 'stock-by-category', 'stock-by-product', 'stock-reports', 'quotation-detail-report', 'purchase-detail-report',
      'gstr2-report', 'gstr3B-report', 'user-group', 'user-group-save', 'outstanding-payable', 'outstanding-receivable','outstanding-receivable-new', 'account-and-stock-transfer', 'config-barcode', 'from-to-address', 'from-to-address-save', 'sale-by-customer', 'sales-summery-by-Product','sale-by-product','purchase-summary-by-supplier','purchase-details-by-supplier',
      'purchase-summary-by-Product','purchase-details-by-product','stock-by-warehouse','cash-receive-party-wise','outstanding-payable-new', 'user-group', 'user-group-save','user-group-access','purchase-analysis-summary-report','ledger-group-summary-report'
    ];

    if (value && value.token && value.token.length > 0) {
      if (value.userId > 0) {
        if (value.consumerId > 0) {
          if (page && page !== null && page !== undefined) {
            let curPage = page.split('/')[1];

            //for Query params
            let isParams = curPage.includes("?");
            if (isParams === true) {
              curPage = curPage.split('?')[0];
            }

            if (curPage !== "") {
              let isPage = allPages.findIndex((x) => x === curPage);
              if (isPage >= 0) {
                // navigate(page);
              } else {
                navigate("/not-found");
              }
            } else {
              navigate("/dashboard");
            }
          } else {
            // history.push("/dashboard");
            navigate("/not-found");
          }
        }
        else {
          if (page && page != null && page !== undefined) {
            let curPage = page.split('/')[1];

            //for Query params
            let isParams = curPage.includes("?");
            if (isParams === true) {
              curPage = curPage.split('?')[0];
            }

            if (curPage !== "") {
              let isPage = allPages.findIndex((x) => x === curPage);
              if (isPage >= 0) {
              } else {
                navigate("/not-found");
              }
            } else {
              navigate("/consumers");
            }
          } else {
            navigate("/");
          }

        }
      }
      else {

        if (page && page != null && page !== undefined) {
          let curPage = page.split('/')[1];

          //for Query params
          let isParams = curPage.includes("?");
          if (isParams === true) {
            curPage = curPage.split('?')[0];
          }

          if (curPage !== "") {
            let isPage = publicPages.findIndex((x) => x === curPage);
            if (isPage >= 0) {
              // navigate(page);
            } else {
              navigate("/");
            }
          }
          else {
            navigate("/");
          }

        } else {
          navigate("/");
        }
      }
    }
    else {
      if (page && page != null && page !== undefined) {
        let curPage = page.split('/')[1];

        //for Query params
        let isParams = curPage.includes("?");
        if (isParams === true) {
          curPage = curPage.split('?')[0];
        }

        if (curPage !== "") {
          let isPage = publicPages.findIndex((x) => x === curPage);
          if (isPage >= 0) {
            // navigate(page);
          } else {
            //history.push("/not-found");
            navigate("/");
          }
        }
        else {
          navigate("/");
        }
      } else {
        navigate("/");
      }
    }
    setLoading(false);
  }
  async function init() {
    try {
      const data: any = await Info(null);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    checkIfLoggedIn();
  }, [location.pathname]);


  useEffect(() => {
    init();
    // setTimeout(() => setLoading(false), 3000);
  }, []);

  return (
    <>

      <ThemeProvider theme={mode === 'dark' ? lightTheme : lightTheme}>
        {isOnline ?
          <>
            <CssBaseline />
            {isLoading ?
              <SplashComponent></SplashComponent>
              : <PreferencesContext.Provider value={preferences}>
                {userContext &&
                  <>
                    <Alerts isOpen={userContext.isAlert} displayMessage={userContext.alertMessage} type={userContext.alertType}></Alerts>
                  </>
                }
                <UnauthorizeDialog isOpen={userContext.isUnauthorized} />
                <Suspense fallback={
                  <SplashComponent></SplashComponent>
                }>
                  <Outlet />
                  <ScrollTop />
                </Suspense>
              </PreferencesContext.Provider>
            }
          </>
          :
          <Box className="offline-img-box">
            <img className="offline-img" src={offlineimg} alt="you are offline" />
          </Box>
        }
      </ThemeProvider>

      {/* <Online>
        
      </Online>
      <Offline>
        <Box className="offline-img-box">
          <img className="offline-img" src={offlineimg} alt="logo" />
        </Box>
      </Offline> */}
    </>
  );
}
export default App;