import React, { useEffect, useState } from "react";
import { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import AsyncCreatableSelect from "react-select/async-creatable";
import { useField } from "formik";

interface CreatablePropsType {
  id: string;
  className?: string;
  placeholder?: string;
  FieldName: string;
  name?: string;
  options: Array<any>;
  isDisabled?: boolean;
  onCreateOption?: any;
  handleChange: any;
  Value: any;
  changeValuePerameter?: any;
  defaultValue?: any;
  onClear?: any;
  isNotCreatable?: boolean;
  CustomOptionComponent?: any;
  isClearable?: boolean;
  CustomFilterOption?: any;
  menuPortalTarget?: any;
  isSearchable?: boolean;
  autoFocus?: boolean;
  optionInnerWidth?: any;
  isInnerWidth?: any;
  defaultOptions?: any;
  onBlur?: any;
  tabIndex?: number;
  tabSelectsValue?: boolean;
  openMenuOnFocus?: boolean;
  onFocus?: any;
  ref?: null;
}

interface AsyncCreatablePropsType {
  id: string;
  className?: string;
  placeholder?: string;
  FieldName: string;
  name?: string;
  options: Array<any>;
  loadOptions?: any;
  isDisabled?: boolean;
  menuPlacement?: any;
  onCreateOption?: any;
  handleChange: any;
  Value: any;
  changeValuePerameter?: any;
  onClear?: any;
  isNotCreatable?: boolean;
  CustomOptionComponent?: any;
  isClearable?: boolean;
  CustomFilterOption?: any;
  menuPortalTarget?: any;
  isSearchable?: boolean;
  noOptionsMessage?: any;
  onInputChange?: any;
  defaultOptions?: any;
  autoFocus?: any;
  optionInnerWidth?: any;
  isInnerWidth?: any;
  onBlur?: any;
  tabIndex?: number;
  openMenuOnFocus?: boolean;
  tabSelectsValue?: boolean;
  onFocus?: any;
  ref?: any;
}

const Option = (props) => {
  return (
    <components.Option {...props}>
      {/* custom html here for option  */}
      <div> {props.children}</div>
    </components.Option>
  );
};

const ValueContainer = (props) => {
  return (
    <components.ValueContainer {...props}>
      <components.Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </components.Placeholder>
      {React.Children.map(props.children, (child) =>
        child && child.type !== components.Placeholder ? child : null
      )}
    </components.ValueContainer>
  );
};

const filterOption = (candidate, input) => {
  return (
    candidate.data.__isNew__ ||
    candidate.label.toLowerCase().includes(input.toLowerCase().trim())
  );
};

export const CustomSelect = (props: CreatablePropsType) => {
  const {
    FieldName,
    options,
    handleChange,
    Value,
    changeValuePerameter,
    onClear,
    isNotCreatable,
    CustomOptionComponent,
    CustomFilterOption,
    placeholder,
    onBlur,
    ...restProps
  } = props;

  let valueChangePerameter = changeValuePerameter ? changeValuePerameter : "";
  const [defaultValue, setDefaultValue] = useState<any>(null);
  const [field, meta, helpers] = useField(`${FieldName}`);

  useEffect((): boolean => {
    const value = options?.find((item) => item.value === Value);
    if (value) setDefaultValue(value);
    else setDefaultValue(null);
    return false;
  }, [options, valueChangePerameter, Value]);

  if (!isNotCreatable) {
    return (
      <CreatableSelect
        {...field}
        {...restProps}
        inputId={restProps.id}
        // menuPlacement="auto"
        tabSelectsValue
        onBlur={onBlur ? onBlur : null}
        placeholder={placeholder ? placeholder : ""}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "#ebf2fe", //active
            primary25: "#f1f6fe", //hover
            primary50: "#f1f6fe", //clicable
          },
        })}
        onChange={(e, ActionType) => {
          if (ActionType.action === "clear") {
            helpers.setValue(null);
            setDefaultValue(null);
            handleChange({ value: null });
            if (props.onClear) {
              onClear();
            }
          } else {
            handleChange(e);
          }
        }}
        components={{
          Option: CustomOptionComponent ? CustomOptionComponent : Option,
          ValueContainer: ValueContainer,
        }}
        options={options}
        value={defaultValue}
        styles={selectStyles(meta, props)}
        filterOption={CustomFilterOption ? CustomFilterOption : filterOption}
      />
    );
  } else {
    return (
      <CreatableSelect
        {...field}
        {...restProps}
        inputId={restProps.id}
        onBlur={onBlur ? onBlur : null}
        isValidNewOption={() => false}
        // menuPlacement="auto"
        tabSelectsValue
        placeholder={placeholder ? placeholder : ""}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "#ebf2fe", //active
            primary25: "#f1f6fe", //hover
            primary50: "#f1f6fe", //clicable
          },
        })}
        onChange={(e, ActionType) => {
          if (ActionType.action === "clear") {
            helpers.setValue(null);
            setDefaultValue(null);
            handleChange({ value: null });
            if (props.onClear) {
              onClear();
            }
          } else {
            handleChange(e);
          }
        }}
        components={{
          Option: CustomOptionComponent ? CustomOptionComponent : Option,
          ValueContainer: ValueContainer,
        }}
        options={options}
        value={defaultValue}
        styles={selectStyles(meta, props)}
        filterOption={CustomFilterOption ? CustomFilterOption : filterOption}
      />
    );
  }
};

export const AsyncCustomSelect = (props: AsyncCreatablePropsType) => {
  const {
    FieldName,
    handleChange,
    Value,
    options,
    changeValuePerameter,
    isNotCreatable,
    CustomOptionComponent,
    CustomFilterOption,
    placeholder,
    onBlur,
    ...restProps
  } = props;

  let valueChangePerameter = changeValuePerameter ? changeValuePerameter : "";
  const [defaultValue, setDefaultValue] = useState<any>(null);
  const [field, meta, helpers] = useField(`${FieldName}`);

  useEffect((): boolean => {
    const value = options?.find((item) => item.value === Value);
    if (value) setDefaultValue(value);
    else setDefaultValue(null);
    return false;
  }, [options, valueChangePerameter, Value]);

  if (!isNotCreatable) {
    return (
      <AsyncCreatableSelect
        {...field}
        {...restProps}
        onBlur={onBlur ? onBlur : null}
        inputId={restProps.id}
        // menuPlacement="auto"
        placeholder={placeholder ? placeholder : ""}
        cacheOptions
        tabSelectsValue
        // isOptionDisabled={(option)=> option?.isDisabled === true}
        options={options}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "#ebf2fe", //active
            primary25: "#f1f6fe", //hover
            primary50: "#f1f6fe", //clicable
          },
        })}
        onChange={(e, ActionType) => {
          if (ActionType.action === "clear") {
            if (props.onClear) {
              props.onClear();
            }
            handleChange({ value: null });
            setDefaultValue(null);
            helpers.setValue(null);
          } else {
            handleChange(e);
          }
        }}
        value={defaultValue}
        styles={selectStyles(meta, props)}
        components={{
          Option: CustomOptionComponent ? CustomOptionComponent : Option,
          ValueContainer: ValueContainer,
        }}
        filterOption={CustomFilterOption ? CustomFilterOption : filterOption}
      />
    );
  } else {
    return (
      <AsyncCreatableSelect
        {...field}
        // menuPlacement="auto"
        {...restProps}
        onBlur={onBlur ? onBlur : null}
        inputId={restProps.id}
        placeholder={placeholder ? placeholder : ""}
        cacheOptions
        tabSelectsValue
        isValidNewOption={() => false}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "#ebf2fe", //active
            primary25: "#f1f6fe", //hover
            primary50: "#f1f6fe", //clicable
          },
        })}
        onChange={(e, ActionType) => {
          if (ActionType.action === "clear") {
            if (props.onClear) {
              props.onClear();
            }
            handleChange({ value: null });
            setDefaultValue(null);
            helpers.setValue(null);
          } else {
            handleChange(e);
          }
        }}
        value={defaultValue}
        styles={selectStyles(meta, props)}
        components={{
          Option: CustomOptionComponent ? CustomOptionComponent : Option,
          ValueContainer: ValueContainer,
        }}
        filterOption={CustomFilterOption ? CustomFilterOption : filterOption}
      />
    );
  }
};

export const AsyncSelect = (props: AsyncCreatablePropsType) => {
  const {
    FieldName,
    handleChange,
    Value,
    options,
    changeValuePerameter,
    isNotCreatable,
    CustomOptionComponent,
    CustomFilterOption,
    placeholder,
    onBlur,
    ...restProps
  } = props;

  const [field, meta, helpers] = useField(`${FieldName}`);

  return (
    <AsyncCreatableSelect
      {...field}
      {...restProps}
      onBlur={onBlur ? onBlur : null}
      inputId={restProps.id}
      placeholder={placeholder ? placeholder : ""}
      cacheOptions
      tabSelectsValue
      isValidNewOption={() => false}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: "#ebf2fe", //active
          primary25: "#f1f6fe", //hover
          primary50: "#f1f6fe", //clicable
        },
      })}
      onChange={(e, ActionType) => {
        if (ActionType.action === "clear") {
          if (props.onClear) {
            props.onClear();
          }
          handleChange({ value: null });
          helpers.setValue(null);
        } else {
          handleChange(e);
        }
      }}
      value={Value}
      styles={selectStyles(meta, props)}
      components={{
        Option: CustomOptionComponent ? CustomOptionComponent : Option,
        ValueContainer: ValueContainer,
      }}
      filterOption={CustomFilterOption ? CustomFilterOption : filterOption}
    />
  );
};

const selectStyles = (meta, props) => {
  return {
    control: (basestyle, state) => ({
      ...basestyle,
      // height: "44px",
      border:
        meta.error && meta.touched
          ? state.isFocused
            ? "1px solid #d32f2f"
            : "1px solid #d32f2f"
          : state.isFocused
          ? "1px solid black"
          : "1px solid $grey-100",
      boxShadow: "none",
      "&:hover": {
        border:
          meta.error && meta.touched ? "1px solid #d32f2f" : "1px solid black",
      },
    }),
    placeholder: (basestyle, state) => ({
      ...basestyle,
      color:
        meta.error && meta.touched
          ? "#d32f2f"
          : state.hasValue || state.selectProps.inputValue
          ? state.isFocused
            ? "#0b0b45"
            : "gray"
          : "gray",
      overflow: "hidden",
      backgroundColor:
        state.hasValue || state.selectProps.inputValue
          ? "white"
          : "transparant",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      position:
        state.hasValue || state.selectProps.inputValue ? "absolute" : "static",
      top: state.hasValue || state.selectProps.inputValue ? -15 : "10%",
      transition: "top 0.3s, font-size 0.1s",
      fontSize: (state.hasValue || state.selectProps.inputValue) && 10,
      paddingLeft:
        state.hasValue || state.selectProps.inputValue ? "3px" : "0px",
      paddingRight:
        state.hasValue || state.selectProps.inputValue ? "3px" : "0px",
    }),
    option: (basestyle, state) => ({
      ...basestyle,
      color: state.isSelected ? "black" : "black",
      border: "1px solid #f1f6fe",
      // backgroundColor: "red !important",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      overflow:
        state.hasValue || state.selectProps.inputValue ? "visible" : "hidden",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: meta.error && meta.touched ? "#d32f2f" : "#adb5bd",
      "&:hover": {
        color: meta.error && meta.touched ? "#d32f2f" : "black",
      },
    }),
    menu: (base, state) => ({
      ...base,
      width: props?.isInnerWidth ? props?.optionInnerWidth : "100% !important", // your desired heights
    }),
    indicatorSeparator: (base, state) => ({
      ...base,
      backgroundColor: meta.error && meta.touched ? "#d32f2f" : "#adb5bd",
    }),
    clearIndicator: (base, state) => ({
      ...base,
      color: meta.error && meta.touched ? "#d32f2f" : "#adb5bd",
      "&:hover": {
        color: meta.error && meta.touched ? "#d32f2f" : "black",
      },
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };
};
