import React, { useContext } from "react";
import { useNavigate, Route, Routes, useLocation, Navigate } from "react-router-dom";
import { PreferencesContext } from "../PreferencesContext";
import App from "../App";
import UserGroupSave from "../screens/UserGroup/UserGroupSave";
import UserGroupList from "../screens/UserGroup/UserGroupList";
import UserGroupAccess from "../screens/UserGroupAccess/UserGroupAccess";
import StockBatchwiseReprot from "../screens/Report/Stock/StockBatchwiseReprot";
import StockReprots from "../screens/Report/Stock/StockReprots";
import SaleSummaryBySalesPerson from "../screens/Report/Stock/SaleSummaryBySalesPerson";
import SaleSummeryByCustomer from "../screens/Report/SaleSummeryByCustomer";
import SaleCustomerwiseReport from "../screens/Report/SaleCustomerwiseReport";
import SaleSummaryByProduct from "../screens/Report/SaleSummaryByProduct";
import SaleDetailsByProduct from "../screens/Report/SaleDetailsByProduct";
import PurchaseSummaryBySupplier from "../screens/Report/PurchaseSummaryBySupplier";
import PurchaseDetailsBySupplier from "../screens/Report/PurchaseDetailsBySupplier";
import PurchaseSummaryByProduct from "../screens/Report/PurchaseSummaryByProduct";
import PurchaseDetailsByProduct from "../screens/Report/PurchaseDetailsByProduct";
import StockByWarehouseReport from "../screens/Report/StockByWarehouseReport";
import CashReceivePartyWise from "../screens/Report/CashReceivePartyWise";
import OutstandingReceivableSummary from "../screens/Report/OutstandingReceivableSummary";
import OutstandingPayableSummary from "../screens/Report/OutstandingPayableSummary";
import LedgerGroupSummaryAdvance from "../screens/Report/LedgerGroupSummaryAdvance";

const SignIn = React.lazy(() => import("../screens/Account/SignIn"));
const ResetPassword = React.lazy(
  () => import("../screens/Account/ResetPassword")
);
const SetPassword = React.lazy(() => import("../screens/Account/SetPassword"));
const ForgotPassword = React.lazy(
  () => import("../screens/Account/ForgotPassword")
);
const NotFound = React.lazy(() => import("../screens/Account/NotFound"));
const ConsumerList = React.lazy(
  () => import("../screens/Consumer/ConsumerList")
);
const Dashboard = React.lazy(() => import("../screens/Dashboard/Dashboard"));
const SupplierList = React.lazy(
  () => import("../screens/Supplier/SupplierList")
);
const CategoryProduct = React.lazy(
  () => import("../screens/CategoryProduct/ProductList")
);
const AreaWiseReport = React.lazy(
  () => import("../screens/Report/AreaWiseReport")
);
const TaxList = React.lazy(() => import("../screens/Tax/TaxList"));
const UnitList = React.lazy(() => import("../screens/Unit/UnitList"));
const PurchaseList = React.lazy(
  () => import("../screens/Purchase/PurchaseList")
);
const SaleList = React.lazy(() => import("../screens/Sale/SaleList"));
const StockList = React.lazy(() => import("../screens/Stock/StockList"));
const StockAdjustment = React.lazy(() => import("../screens/Stock/StockAdjustmentList"));
const StockAdjustmentSave = React.lazy(() => import("../screens/Stock/StockAdjustment"));
const UserList = React.lazy(() => import("../screens/User/UserList"));
const StaffList = React.lazy(() => import("../screens/Staff/StaffList"));
const BranchList = React.lazy(() => import("../screens/Branch/BranchList"));
const FirmList = React.lazy(() => import("../screens/Firm/FirmList"));
const Gstr2Report = React.lazy(() => import("../screens/GSTR2/GSTR2Reports"));
const Gstr3BReport = React.lazy(() => import("../screens/GSTR3B/GSTR3BReport"));
const CreditNoteList = React.lazy(
  () => import("../screens/CreditNote/CreditNoteList")
);
const DebitNoteList = React.lazy(
  () => import("../screens/DebitNote/DebitNoteList")
);
const TransporterList = React.lazy(
  () => import("../screens/Transporter/TransporterList")
);
const BuyerList = React.lazy(() => import("../screens/Buyer/BuyerList"));
const CategoryList = React.lazy(
  () => import("../screens/CategoryProduct/CategoryList")
);
const AccountGroupList = React.lazy(
  () => import("../screens/AccountGroup/AccountGroupList")
);

const MyProfilePage = React.lazy(
  () => import("../screens/Myprofile/MyProfilePage")
);
const GstReport = React.lazy(() => import("../screens/GSTReport/GSTReport"));
const SupplierSave = React.lazy(
  () => import("../screens/Supplier/SupplierSave")
);
const ProductSave = React.lazy(
  () => import("../screens/CategoryProduct/ProductSave")
);
const TaxSave = React.lazy(() => import("../screens/Tax/TaxSave"));
const UnitSave = React.lazy(() => import("../screens/Unit/UnitSave"));
const ManageUser = React.lazy(() => import("../screens/User/ManageUser"));
const StaffSave = React.lazy(() => import("../screens/Staff/StaffSave"));
const BranchSave = React.lazy(() => import("../screens/Branch/BranchSave"));

const FirmSave = React.lazy(() => import("../screens/Firm/FirmSave"));

const InvoiceDailyReport = React.lazy(
  () => import("../screens/Report/InvoiceDailyReport")
);
const PurchaseDailyReport = React.lazy(
  () => import("../screens/Report/PurchaseDailyReport")
);
const StaffSummaryReport = React.lazy(
  () => import("../screens/Report/StaffSummaryReport")
);
const SalesRegister = React.lazy(
  () => import("../screens/Report/SalesRegisterReport")
);
const PurchaseRegister = React.lazy(
  () => import("../screens/Report/PurchaseRegisterReport")
);

//const PurchaseSaveNew = React.lazy(() => import('../screens/Purchase/PurchaseSaveNew'));
const PurchaseSave = React.lazy(
  () => import("../screens/Purchase/PurchaseSave")
);
const SaleSave = React.lazy(() => import("../screens/Sale/SaleSave"));
const SaleSaveReturn = React.lazy(
  () => import("../screens/SaleReturn/SaleSaveReturn")
);
const SaleListReturn = React.lazy(
  () => import("../screens/SaleReturn/SaleListReturn")
);
//const SaleSaveNew = React.lazy(() => import('../screens/Sale/SaleSaveNew'));
const UserSave = React.lazy(() => import("../screens/User/UserSave"));
const HelpCenter = React.lazy(() => import("../screens/Helpcenter/HelpCenter"));
const Settings = React.lazy(() => import("../screens/Settings/Settings"));
const ReportMenu = React.lazy(() => import("../screens/ReportMenu/ReportMenu"));
const InvoiceSetting = React.lazy(
  () => import("../screens/InvoiceSetting/InvoiceSetting")
);
const ConsumerSave = React.lazy(
  () => import("../screens/Consumer/ConsumerSave")
);
const CategorySave = React.lazy(
  () => import("../screens/CategoryProduct/CategorySave")
);
const ShippingAddressList = React.lazy(
  () => import("../screens/DeliveryAddress/DeliveryAddressList")
);
const ShippingAddressSave = React.lazy(
  () => import("../screens/DeliveryAddress/DeliveryAddressSave")
);

const BuyerSave = React.lazy(() => import("../screens/Buyer/BuyerSave"));
const Nav = React.lazy(() => import("./navbar"));
const DrawerNav = React.lazy(() => import("./DrawerBar"));
const FinancialYearlyData = React.lazy(
  () => import("../screens/Account/FinancialYearlyData")
);
const ChallanList = React.lazy(() => import("../screens/Challan/ChallanList"));
const ChallanSave = React.lazy(() => import("../screens/Challan/ChallanSave"));
const CreditNoteSave = React.lazy(
  () => import("../screens/CreditNote/CreditNoteSave")
);
const DebitNoteSave = React.lazy(
  () => import("../screens/DebitNote/DebitNoteSave")
);

const TransporterSave = React.lazy(
  () => import("../screens/Transporter/TransporterSave")
);
const AccountGroupSave = React.lazy(
  () => import("../screens/AccountGroup/AccountGroupSave")
);
const PaymentList = React.lazy(() => import("../screens/Payment/PaymentList"));
const PaymentSave = React.lazy(() => import("../screens/Payment/PaymentSave"));
const SalesReceiptList = React.lazy(
  () => import("../screens/SalesReceipt/SalesReceiptList")
);
const SalesReceiptSave = React.lazy(
  () => import("../screens/SalesReceipt/SalesReceiptSave")
);

const VoucherList = React.lazy(() => import("../screens/Voucher/VoucherList"));
const VoucherSave = React.lazy(() => import("../screens/Voucher/VoucherSave"));

const WarehouseList = React.lazy(
  () => import("../screens/Warehouse/WarehouseList")
);
const WarehouseSave = React.lazy(
  () => import("../screens/Warehouse/WarehouseSave")
);
const LedgerGroupList = React.lazy(
  () => import("../screens/LedgerGroup/LedgerGroupList")
);
const LedgerGroupSave = React.lazy(
  () => import("../screens/LedgerGroup/LedgerGroupSave")
);
const StockStatementReport = React.lazy(
  () => import("../screens/Report/Stock/StockStatementReport")
);
const ProfitAndLossReport = React.lazy(
  () => import("../screens/Report/ProfitAndLossReport")
);

const Subscription = React.lazy(
  () => import("../screens/Subscription/Subscription")
);

const DayBookReport = React.lazy(
  () => import("../screens/Report/DayBookReport")
);
const TrialBalance = React.lazy(() => import("../screens/Report/TrialBalance"));
const BalanceSheet = React.lazy(() => import("../screens/Report/BalanceSheet"));
const LedgerVoucher = React.lazy(
  () => import("../screens/Report/LedgerVoucher")
);
const LedgerMonthlySummary = React.lazy(
  () => import("../screens/Report/LedgerMonthlySummary")
);
const LedgerGroupSummary = React.lazy(
  () => import("../screens/Report/LedgerGroupSummary")
);
const TermsAndConditions = React.lazy(
  () => import("../screens/TermsAndConditions/TermsAndConditions")
);
const Android = React.lazy(() => import("../screens/Account/Android"));
const PurchaseReturnReport = React.lazy(
  () => import("../screens/Report/PurchaseReturnReport")
);
const SaleReturnReport = React.lazy(
  () => import("../screens/Report/SaleReturnReport")
);
const PrivacyAndPolicy = React.lazy(
  () => import("../screens/PrivacyAndPolicy/PrivacyAndPolicy")
);
// const EmailDialog = React.lazy(() => import("../Components/EmailSendDailog"));
const EwayBill = React.lazy(() => import("../screens/EWayBill/EWayBill"));

const EwayBillUpdate = React.lazy(
  () => import("../screens/EWayBill/EWayBillUpdate")
);
const EwayBillExtend = React.lazy(
  () => import("../screens/EWayBill/EWayBillExtend")
);

const Gstr1Report = React.lazy(() => import("../screens/GSTR1/GSTR1Report"));
const PriceList = React.lazy(() => import("../screens/Price/PriceList"));
const POS = React.lazy(() => import("../screens/POS/pos"));
const StockSummaryBySupplier = React.lazy(() => import("../screens/Report/StockSummaryBySupplierReport"));
const StockSummaryByBuyer = React.lazy(() => import("../screens/Report/StockSummaryByBuyerReport"));
const StockDetailsByBuyer = React.lazy(() => import("../screens/Report/StockDetailsByBuyerReport"));
const StockSummaryByProductAndBuyer = React.lazy(() => import("../screens/Report/StockSummaryByProductAndBuyerReport"));
const FreeGoodsSummaryByCategory = React.lazy(() => import("../screens/Report/FreeGoodsSummaryByCategory"));
const FreeGoodDetailByBuyer = React.lazy(() => import("../screens/Report/FreeGoodsDetailByBuyerReport"));
const FreeGoodsSummaryByBuyer = React.lazy(() => import("../screens/Report/FreeGoodsSummaryByBuyerReport"));
const AreaList = React.lazy(() => import("../screens/Area/AreaList"));
const AreaSave = React.lazy(() => import("../screens/Area/AreaSave"));
const SaleSaveLWH = React.lazy(() => import("../screens/Sale/SaleSaveLWH"));
const StockByCategoryReport = React.lazy(
  () => import("../screens/Report/StockBycategoryReport")
);

const QuotationDetailReport = React.lazy(
  () => import("../screens/Report/QuotationDetailReport")
);
const PurchaseDetailReport = React.lazy(
  () => import("../screens/Report/PurchaseDetailReport")
);
const OutstandingReceivable = React.lazy(
  () => import("../screens/Report/OutstandingReceivable")
);
const OutstandingReceivableNew = React.lazy(
  () => import("../screens/Report/OutstandingReceivableNew")
);
const OutstandingPayable = React.lazy(
  () => import("../screens/Report/OutstandingPayable")
);

const OutstandingPayableNew = React.lazy(
  () => import("../screens/Report/OutstandingPayableNew")
);

const AccountsTransfer = React.lazy(
  () => import("../screens/AccountTransfer/AccountsTransfer")
);

const ConfigBarcode = React.lazy(
  () => import("../screens/Config/Barcode")
);
const FromToAddressList = React.lazy(
  () => import("../screens/FromToAddress/FromToAddressList")
);
const FromToAddressSave = React.lazy(
  () => import("../screens/FromToAddress/FromToAddressSave")
);
const PurchaseAnalysisSummaryReport = React.lazy(
  () => import("../screens/Report/PurchaseAnalysisSummaryReport")
);
export default function RootNavigation({ colorScheme }: { colorScheme: any }) {
  return <RootNavigator colorScheme={colorScheme} />;
}

function RootNavigator({ colorScheme }: { colorScheme: any }) {
  const { userContext, updateUserContext } = useContext(PreferencesContext);
  return (
    <Routes>
      <Route element={<App />} path="/" >
        <Route element={<SignIn />} path="/" />
        <Route element={<ResetPassword />} path="/reset-password" />
        <Route element={<SetPassword />} path="/set-password" />
        <Route element={<ForgotPassword />} path="/forgot-password" />
        <Route element={<NotFound />} path="/not-found" />
        <Route element={<ManageUser />} path="/manage-user" />
        <Route element={<TermsAndConditions />} path="/terms" />
        <Route element={<PrivacyAndPolicy />} path="/privacy-policy" />
        <Route element={<Android />} path="/android" />
        <Route element={<NotFound />} path="*" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><Dashboard /></Nav></AuthorizationForUser>} path="dashboard" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><SupplierList /></Nav></AuthorizationForUser>} path="suppliers" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><CategoryProduct /></Nav></AuthorizationForUser>} path="products" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><AreaWiseReport /></Nav></AuthorizationForUser>} path="areawise-report" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><StockByCategoryReport /></Nav></AuthorizationForUser>} path="stock-by-category" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><StockByWarehouseReport/></Nav></AuthorizationForUser>} path='stock-by-warehouse'/>
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><StockBatchwiseReprot /></Nav></AuthorizationForUser>} path="stock-by-product" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}> <SaleCustomerwiseReport /></Nav></AuthorizationForUser>} path="sale-by-customer" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><StockReprots /></Nav></AuthorizationForUser>} path="stock-reports" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><QuotationDetailReport /></Nav></AuthorizationForUser>} path="quotation-detail-report" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><PurchaseDetailReport /></Nav></AuthorizationForUser>} path="purchase-detail-report" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><PurchaseSummaryBySupplier/></Nav></AuthorizationForUser>} path="purchase-summary-by-supplier"></Route>
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><PurchaseDetailsBySupplier/></Nav></AuthorizationForUser>} path="purchase-details-by-supplier"></Route>
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><PurchaseSummaryByProduct/></Nav></AuthorizationForUser>} path="purchase-summary-by-Product"></Route>
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><PurchaseDetailsByProduct/></Nav></AuthorizationForUser>} path="purchase-details-by-product"></Route>
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><TaxList /></Nav></AuthorizationForUser>} path="taxes" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><UnitList /></Nav></AuthorizationForUser>} path="units" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><Settings /></Nav></AuthorizationForUser>} path="settings" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><ReportMenu /></Nav></AuthorizationForUser>} path="/report-menu" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><PurchaseList /></Nav></AuthorizationForUser>} path="/purchase" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><SaleList /></Nav></AuthorizationForUser>} path="/invoice" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><CreditNoteList /></Nav></AuthorizationForUser>} path="/credit-note" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><DebitNoteList /></Nav></AuthorizationForUser>} path="/debit-note" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><ChallanList /></Nav></AuthorizationForUser>} path="/delivery-challan" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><CreditNoteSave /></Nav></AuthorizationForUser>} path={"/credit-note-save"} />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><DebitNoteSave /></Nav></AuthorizationForUser>} path={"/debit-note-save"} />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><SaleListReturn /></Nav></AuthorizationForUser>} path="/invoicereturn" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><Gstr1Report /></Nav></AuthorizationForUser>} path="/gstr1-report" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><Gstr2Report /></Nav></AuthorizationForUser>} path="/gstr2-report" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><Gstr3BReport /></Nav></AuthorizationForUser>} path="/gstr3B-report" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><StockList /></Nav></AuthorizationForUser>} path="/stock" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><StockAdjustment /></Nav></AuthorizationForUser>} path="/stock-adjustment" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><StockAdjustmentSave /></Nav></AuthorizationForUser>} path="/stock-adjustment-save" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><UserList /></Nav></AuthorizationForUser>} path="/user" />

        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><UserGroupList /></Nav></AuthorizationForUser>} path="/user-group" />

        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><UserGroupAccess/></Nav></AuthorizationForUser>} path="/user-group-access" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><StaffList /></Nav></AuthorizationForUser>} path="/staff" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><CategoryList /></Nav></AuthorizationForUser>} path="/product-category" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><BranchList /></Nav></AuthorizationForUser>} path="/branches" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><FirmList /></Nav></AuthorizationForUser>} path="/firms" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><BuyerList /></Nav></AuthorizationForUser>} path="/buyers" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><GstReport /></Nav></AuthorizationForUser>} path="/gst-report" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><MyProfilePage /></Nav></AuthorizationForUser>} path="/my-profile" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><CategorySave /></Nav></AuthorizationForUser>} path="/product-category-save" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><ProductSave /></Nav></AuthorizationForUser>} path="/product-save" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><ChallanSave /></Nav></AuthorizationForUser>} path="/delivery-challan-save" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><UnitSave /></Nav></AuthorizationForUser>} path="/unit-save" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><HelpCenter /></Nav></AuthorizationForUser>} path="/help-center" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><PurchaseSave /></Nav></AuthorizationForUser>} path="/purchase-save" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><InvoiceSetting /></Nav></AuthorizationForUser>} path="/invoice-Setting" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><AccountsTransfer /></Nav></AuthorizationForUser>} path="/account-and-stock-transfer" />

        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}>
          {userContext.consumerId === 32 ? <SaleSaveLWH /> :
            <SaleSave />}</Nav></AuthorizationForUser>} path="/invoice-save" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><SaleSaveReturn /></Nav></AuthorizationForUser>} path="/SaleSaveReturn" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><BuyerSave /></Nav></AuthorizationForUser>} path="/buyer-save" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><SupplierSave /></Nav></AuthorizationForUser>} path="/supplier-save" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><UserSave /></Nav></AuthorizationForUser>} path="/user-save" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><UserGroupSave /></Nav></AuthorizationForUser>} path="/user-group-save" />
        <Route element={<AuthorizationForSuperAdminOnly><Nav colorScheme={colorScheme} changeMode={colorScheme}><ConsumerSave /></Nav></AuthorizationForSuperAdminOnly>} path="/consumer-save" />
        <Route element={<AuthorizationForSuperAdminOnly><Nav colorScheme={colorScheme} changeMode={colorScheme}><ConsumerList /></Nav></AuthorizationForSuperAdminOnly>} path="/consumers" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><StaffSave /></Nav></AuthorizationForUser>} path="/staff-save" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><TaxSave /></Nav></AuthorizationForUser>} path="/tax-save" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><BranchSave /></Nav></AuthorizationForUser>} path="/branch-save" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><FirmSave /></Nav></AuthorizationForUser>} path="/firm-save" />
        <Route
          element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><FinancialYearlyData /></Nav></AuthorizationForUser>}
          path="/financial-yearly-data"
        />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><InvoiceDailyReport /></Nav></AuthorizationForUser>} path="/daily-sales-report" />
        <Route
          element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><StaffSummaryReport /></Nav></AuthorizationForUser>}
          path="/staff-summary-report"
        />
        <Route
          element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><SaleSummaryBySalesPerson /></Nav></AuthorizationForUser>}
          path="/sales-details-by-saleperson"
        />
        <Route
          element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><SaleSummeryByCustomer /></Nav></AuthorizationForUser>}
          path="/sales-summery-by-customer"
        />
         <Route
          element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><OutstandingReceivableSummary /></Nav></AuthorizationForUser>}
          path="/outstanding-receivable-summary"
        />
         <Route
          element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><OutstandingPayableSummary /></Nav></AuthorizationForUser>}
          path="/outstanding-payable-summary"
        />
        <Route
          element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><SaleSummaryByProduct /></Nav></AuthorizationForUser>}
          path="sales-summery-by-Product"
        />
        <Route
          element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><SaleDetailsByProduct/></Nav></AuthorizationForUser>}
          path="sale-by-product"
        />
        <Route
          element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><PurchaseDailyReport /></Nav></AuthorizationForUser>}
          path="/daily-purchases-report"
        />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><SalesRegister /></Nav></AuthorizationForUser>} path="/sales-register" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><PurchaseRegister /></Nav></AuthorizationForUser>} path="/purchase-register" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><ShippingAddressList /></Nav></AuthorizationForUser>} path="/shipping-address" />
        <Route
          element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><ShippingAddressSave /></Nav></AuthorizationForUser>}
          path="/shipping-address-save"
        />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><FromToAddressList /></Nav></AuthorizationForUser>} path="/from-to-address" />
        <Route
          element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><FromToAddressSave /></Nav></AuthorizationForUser>}
          path="/from-to-address-save"
        />
        <Route element={<AuthorizationForClientAdmin><Nav colorScheme={colorScheme} changeMode={colorScheme}><AccountGroupList /></Nav></AuthorizationForClientAdmin>} path="/ledger" />
        <Route element={<AuthorizationForClientAdmin><Nav colorScheme={colorScheme} changeMode={colorScheme}><AccountGroupSave /></Nav></AuthorizationForClientAdmin>} path="/ledger-save" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><TransporterList /></Nav></AuthorizationForUser>} path={"/transporters"} />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><TransporterSave /></Nav></AuthorizationForUser>} path={"/transporter-save"} />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><PaymentList /></Nav></AuthorizationForUser>} path={"/payment-made"} />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><PaymentSave /></Nav></AuthorizationForUser>} path={"/payment-made-save"} />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><SalesReceiptList /></Nav></AuthorizationForUser>} path={"/payment-received"} />
        <Route
          element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><SalesReceiptSave /></Nav></AuthorizationForUser>}
          path={"/payment-received-save"}
        />
        <Route element={<AuthorizationForClientAdmin><Nav colorScheme={colorScheme} changeMode={colorScheme}><VoucherList /></Nav></AuthorizationForClientAdmin>} path={"/voucher"} />
        <Route element={<AuthorizationForClientAdmin><Nav colorScheme={colorScheme} changeMode={colorScheme}><VoucherSave /></Nav></AuthorizationForClientAdmin>} path={"/voucher-save"} />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><WarehouseList /></Nav></AuthorizationForUser>} path={"/warehouses"} />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><WarehouseSave /></Nav></AuthorizationForUser>} path={"/warehouse-save"} />
        <Route element={<AuthorizationForClientAdmin><Nav colorScheme={colorScheme} changeMode={colorScheme}><LedgerGroupList /></Nav></AuthorizationForClientAdmin>} path={"/ledger-groups"} />
        <Route element={<AuthorizationForClientAdmin><Nav colorScheme={colorScheme} changeMode={colorScheme}><LedgerGroupSave /></Nav></AuthorizationForClientAdmin>} path={"/ledger-group-save"} />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><StockStatementReport /></Nav></AuthorizationForUser>} path={"/stock-statement"} />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><ProfitAndLossReport /></Nav></AuthorizationForUser>} path={"/profit-and-loss"} />
        <Route
          element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><LedgerGroupSummary /></Nav></AuthorizationForUser>}
          path="/ledger-group-summary"
        />
        <Route
          element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><LedgerMonthlySummary /></Nav></AuthorizationForUser>}
          path="/ledger-monthly-summary"
        />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><Subscription /></Nav></AuthorizationForUser>} path="/subscription" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><LedgerVoucher /></Nav></AuthorizationForUser>} path="/ledger-voucher" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><BalanceSheet /></Nav></AuthorizationForUser>} path="/balance-sheet" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><DayBookReport /></Nav></AuthorizationForUser>} path="/day-book" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><TrialBalance /></Nav></AuthorizationForUser>} path="/trial-balance" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><PurchaseReturnReport /></Nav></AuthorizationForUser>} path="/purchase-return" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><SaleReturnReport /></Nav></AuthorizationForUser>} path="/sale-return" />
        {/* <Route element={EmailDialog} path="/email-dialog" /> */}
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><EwayBill /></Nav></AuthorizationForUser>} path="/e-way-bill" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><EwayBillUpdate /></Nav></AuthorizationForUser>} path="/e-way-bill-update" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><EwayBillExtend /></Nav></AuthorizationForUser>} path="/e-way-bill-extend" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><PriceList /></Nav></AuthorizationForUser>} path="/price-list" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><POS /></Nav></AuthorizationForUser>} path="/pos" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><StockSummaryBySupplier /></Nav></AuthorizationForUser>} path="/stock-summary-by-supplier-report" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><FreeGoodsSummaryByCategory /></Nav></AuthorizationForUser>} path="/free-good-summary-by-category-report" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><FreeGoodDetailByBuyer /></Nav></AuthorizationForUser>} path="/free-good-detail-by-buyer-report" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><FreeGoodsSummaryByBuyer /></Nav></AuthorizationForUser>} path="/free-good-summary-by-buyer-report" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><StockSummaryByBuyer /></Nav></AuthorizationForUser>} path="/stock-summary-by-buyer-report" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><StockDetailsByBuyer /></Nav></AuthorizationForUser>} path="/stock-detail-by-buyer-report" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><StockSummaryByProductAndBuyer /></Nav></AuthorizationForUser>} path="/stock-summary-by-product-buyer-report" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><OutstandingPayable /></Nav></AuthorizationForUser>} path="outstanding-payable" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><OutstandingPayableNew /></Nav></AuthorizationForUser>} path="outstanding-payable-new" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><OutstandingReceivable /></Nav></AuthorizationForUser>} path="outstanding-receivable" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><OutstandingReceivableNew /></Nav></AuthorizationForUser>} path="outstanding-receivable-new" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><CashReceivePartyWise/></Nav></AuthorizationForUser>} path="cash-receive-party-wise"></Route>
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><LedgerGroupSummaryAdvance/></Nav></AuthorizationForUser>} path="ledger-group-summary-report"></Route>
        {[0, 1, 25].includes(userContext.consumerId) &&
          <>
            <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><AreaList /></Nav></AuthorizationForUser>} path="/area" />
            <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><AreaSave /></Nav></AuthorizationForUser>} path="/area-save" />
          </>
        }
        {/* config routes */}
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><ConfigBarcode /></Nav></AuthorizationForUser>} path="/config-barcode" />
        <Route element={<AuthorizationForUser><Nav colorScheme={colorScheme} changeMode={colorScheme}><PurchaseAnalysisSummaryReport /></Nav></AuthorizationForUser>} path="/purchase-analysis-summary-report" />
      </Route>
      {/* <Route element={<NavContainer colorScheme={undefined}/>} /> */}
    </Routes>
  );
}

// only super admin can access these routes
function AuthorizationForSuperAdminOnly(props) {
  const { userContext, updateUserContext } = useContext(PreferencesContext);
  if (userContext.userRole === "superadmin") {
    return props.children;
  } else {
    return <Navigate to={'/not-found'} />;
  }
}

// user and uper level can access these routes
function AuthorizationForUser(props) {
  const { userContext, updateUserContext } = useContext(PreferencesContext);
  if (['user', 'clientadmin', 'superadmin'].includes(userContext.userRole?.toString()?.trim())) {
    return props.children;
  } else {
    return <Navigate to={'/not-found'} />;
  }
}

// client admin and uper level can access these routes
function AuthorizationForClientAdmin(props) {
  const { userContext, updateUserContext } = useContext(PreferencesContext);
  if (['clientadmin', 'superadmin'].includes(userContext.userRole?.toString()?.trim())) {
    return props.children;
  } else {
    return <Navigate to={'/not-found'} />;
  }
}